import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatDistance } from "date-fns";
import React, { useMemo } from "react";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { getVendorFromCloudProviderType } from "../../../utils/cloudProviderConverter";
import copyText from "../copyText";
import { groupBy } from "lodash";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { faCloud, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { IntegrationStatusVariant } from "@/features/admin/constants";
import { getIntegrationStatusColor } from "@/features/admin/utils";
import { IntegrationStatusTag } from "@/features/admin/components/IntegrationStatusTag";
import DataStatusTooltip from "@/features/admin/components/DataStatusTooltip";
import { ExpandableCell } from "@ternary/api-lib/ui-lib/charts/Table/ExpandableCell";

type IntegrationData = {
  tenantDocID: string;
  latestRefresh: string | null;
  latestUpstream: string | null;
  name: string;
  refreshDifference: number;
  statusVariant: IntegrationStatusVariant;
  tenantName: string;
};

type Integration = IntegrationData & {
  providerType: CloudProviderType;
};

type AggregatedIntegration = IntegrationData & {
  providerType: CloudProviderType[];
};

type RowData = (Integration | AggregatedIntegration) & {
  hasTenantAccess: boolean;
};
type TableData = RowData & {
  subRows?: RowData[];
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  integrations: Integration[];
  isLoading: boolean;
  onInteraction: (interaction: MspDataIntegrationTable.Interaction) => void;
}

function GroupedStatus(props: { subRows: RowData[] }) {
  const theme = useTheme();

  const groupedByStatus = groupBy(props.subRows, "statusVariant");

  function renderTag({
    count,
    key,
    tooltipContent,
    variant,
  }: {
    count: number;
    key: string;
    tooltipContent: string;
    variant: IntegrationStatusVariant;
  }) {
    const color = getIntegrationStatusColor(variant, theme);
    return (
      <Tooltip content={tooltipContent} key={key} width="200px">
        <IntegrationStatusTag variant={variant}>
          <Text>{count}</Text>
          <Icon icon={faCloud} color={color} size="1x" />
        </IntegrationStatusTag>
      </Tooltip>
    );
  }

  return (
    <Flex
      justifyContent="center"
      style={{
        gap: theme.space_xs,
      }}
      alignItems="center"
    >
      {Object.keys(groupedByStatus)
        .reverse()
        .map((status) => {
          const statusTooltip = copyText[`groupedDataRefreshStatus_${status}`];
          const tooltipContent = `${groupedByStatus[status].length} ${statusTooltip}`;
          const key = `${props.subRows[0].tenantDocID}-${status}`;

          return renderTag({
            count: groupedByStatus[status].length,
            key,
            tooltipContent,
            variant: status as IntegrationStatusVariant,
          });
        })}
    </Flex>
  );
}

export function MspDataIntegrationTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const hasAccess = cell.row.original.hasTenantAccess;

          return (
            <ExpandableCell row={cell.row}>
              {hasAccess ? (
                <Link
                  target="_blank"
                  to={{
                    pathname: paths._admin,
                    search: `?tenant_id=${tenantDocID}&tab=clouds`,
                  }}
                >
                  {cell.getValue()}
                </Link>
              ) : (
                <Text
                  appearance="link"
                  color={theme.link_color}
                  onClick={() =>
                    props.onInteraction({
                      type: MspDataIntegrationTable.INTERACTION_LINK_CLICKED,
                      tenantDocID,
                    })
                  }
                >
                  {cell.getValue()}
                </Text>
              )}
            </ExpandableCell>
          );
        },
        header: copyText.tableHeaderTenantName,
      }),
      columnHelper.accessor("providerType", {
        cell: (cell) => {
          const value = cell.getValue();
          if (Array.isArray(value)) {
            return (
              <Text truncate>
                {value.map((e) => getVendorFromCloudProviderType(e)).join(", ")}
              </Text>
            );
          }
          return getVendorFromCloudProviderType(value);
        },
        header: copyText.tableHeaderProviderType,
      }),
      columnHelper.accessor("name", {
        cell: (cell) => {
          return <Text truncate>{cell.getValue()}</Text>;
        },
        header: copyText.tableHeaderName,
      }),
      columnHelper.accessor("refreshDifference", {
        cell: (cell) => {
          const latestRefresh = cell.row.original.latestRefresh;
          const isExpandableRow =
            cell.row.depth === 0 && cell.row.subRows.length > 0;

          if (!latestRefresh || latestRefresh.startsWith("00")) {
            return "--";
          }

          const now = new Date();
          const absoluteRefresh = new Date(latestRefresh);

          const refreshDifference = formatDistance(now, absoluteRefresh);
          const content = copyText.lastRefresh.replace(
            "%VALUE%",
            refreshDifference
          );

          if (isExpandableRow) {
            return (
              <Tooltip
                content={copyText.groupedLastRefreshTooltip}
                width="200px"
              >
                {content}
              </Tooltip>
            );
          }

          return content;
        },
        header: () => (
          <Tooltip
            content={copyText.tableHeaderLastRefreshTooltip}
            width="300px"
          >
            {copyText.tableHeaderLastRefresh} <Icon icon={faInfoCircle} />
          </Tooltip>
        ),
      }),
      columnHelper.accessor("statusVariant", {
        cell: (cell) => {
          const isFirstDepth = cell.row.depth === 0;
          const isExpandableRow = isFirstDepth && cell.row.subRows.length > 0;

          if (isExpandableRow) {
            return <GroupedStatus subRows={cell.row.original.subRows ?? []} />;
          }

          const latestRefresh = cell.row.original.latestRefresh;
          const latestUpstream = cell.row.original.latestUpstream;

          return (
            <DataStatusTooltip
              latestRefresh={latestRefresh}
              latestUpstream={latestUpstream}
              showOutline
              style={{ marginLeft: 0 }}
            />
          );
        },
        header: () => (
          <Tooltip
            content={
              <Flex direction="column" style={{ gap: theme.space_xxs }}>
                <Text align="left" color={theme.text_color_inverse}>
                  {copyText.tableHeaderStatusTooltip}
                </Text>
                {Object.values(IntegrationStatusVariant).map((status) => {
                  const color = getIntegrationStatusColor(status, theme);
                  const content =
                    copyText[`tableHeaderStatusTooltip_${status}`];
                  return (
                    <Text
                      align="left"
                      color={theme.text_color_inverse}
                      fontSize={theme.fontSize_small}
                      key={`status-${status}`}
                    >
                      <Icon icon={faCloud} color={color} /> {content}
                    </Text>
                  );
                })}
              </Flex>
            }
            width="300px"
          >
            {copyText.tableHeaderStatus} <Icon icon={faInfoCircle} />
          </Tooltip>
        ),
        size: 100,
      }),
    ],
    [props.integrations]
  );

  const data = useMemo(() => {
    return props.integrations.map((summary) => {
      const grant = authenticatedUser.grants.find(
        (grant) => grant.tenantDocID === summary.tenantDocID
      );

      return { ...summary, hasTenantAccess: !!grant };
    });
  }, [props.integrations]);

  const aggregatedByTenant = useMemo(() => {
    const groupedByTenant = groupBy(data, "tenantDocID");
    return Object.keys(groupedByTenant).map((tenantDocID) => {
      const integrations = groupedByTenant[tenantDocID];
      const tenantName = integrations[0].tenantName;
      const hasTenantAccess = integrations[0].hasTenantAccess;

      // Alphabetically sorted unique providers
      const providerType = Array.from(
        integrations.reduce((acc, integration) => {
          return acc.add(integration.providerType);
        }, new Set<CloudProviderType>())
      ).sort();

      const name = integrations
        .map((integration) => integration.name)
        .join(", ");

      // The most stale refresh date
      const latestRefresh = integrations.reduce((acc: string | null, curr) => {
        if (!acc) {
          return curr.latestRefresh;
        }
        if (!curr.latestRefresh) {
          return acc;
        }

        return new Date(acc) <= new Date(curr.latestRefresh)
          ? acc
          : curr.latestRefresh;
      }, null);

      return {
        ...integrations[0],
        hasTenantAccess,
        latestRefresh,
        name,
        providerType,
        subRows: integrations.length > 1 ? integrations : undefined,
        tenantDocID,
        tenantName,
      };
    });
  }, [data]);

  return (
    <Table
      columns={columns}
      data={aggregatedByTenant}
      expandable
      getSubRows={(row) => row.subRows}
      initialState={{ sorting: [{ id: "statusVariant", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspDataIntegrationTable.INTERACTION_LINK_CLICKED =
  "MspChildDataIntegrationTable.INTERACTION_LINK_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspDataIntegrationTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspDataIntegrationTable {
  export type Interaction = InteractionLinkClicked;
}

export default MspDataIntegrationTable;
