/* prettier-ignore */
export default {
  announceKitWidgetID: "https://announcekit.co/widgets/v2/2RdKeY",
  companyWebsite: "https://ternary.app",
  gcpGeneralPurposeCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#general-purpose_commitments",
  gcpComputeOptimizedCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#compute-optimized_commitments",
  readmeBillingDataPerformanceModeDocumentation: "https://docs.ternary.app/docs/billing-data-performance-mode",
  readmeBudgetsDocumentation: "https://docs.ternary.app/docs/budgets",
  readmeCostAllocationDocumentation: "https://docs.ternary.app/docs/cost-allocation",
  readmeCostCompareDocumentation: "https://docs.ternary.app/docs/aws-gcp-cost-compare",
  readmeCustomLabelsDocumentation: "https://docs.ternary.app/docs/custom-labels-1",
  readmeCustomGroupingDocumentation: "https://docs.ternary.app/docs/custom-labels#custom-groupings",
  readmeGCPOnboardingDocumentation: "https://docs.ternary.app/docs/google-cloud-platform",
  readmeJiraIntegrationDocumentation: "https://docs.ternary.app/docs/jira",
  readmeRampPlanDocumentation: "https://docs.ternary.app/docs/ramp-plans",
  readmeRolePermissionsDocumentation: "https://docs.ternary.app/docs/users-and-roles",
  readmeSlackIntegrationDocumentation: "https://docs.ternary.app/docs/slack",
};
